import { simplifyStringForSearch } from '@app/shared/extra/utils';

declare global {
  interface Array<T> {
    singleOrNull(callbackfn: (value: T) => boolean): T | null;

    singleOrNull<T>(callbackfn: (value: T) => boolean): T | null;

    firstOrNull(callbackfn: (value: T) => boolean): T | null;

    firstOrNull<T>(callbackfn: (value: T) => boolean): T | null;

    lastOrNull(callbackfn: (value: T) => boolean): T | null;

    lastOrNull<T>(callbackfn: (value: T) => boolean): T | null;

    distinctBy(property: any): T[];

    last(): T | undefined;

    first(): T | undefined;

  }

  interface String {
    compareTo(to: string): number;

    toBooleanString(): string;
  }

  interface Number {
    compareTo(to: number): number;

    replaceCommaDecimalSeparator(): string;
  }

  interface Date {
    compareTo(to: Date): number;
  }

}

if (!Number.prototype.compareTo) {
  Object.defineProperty(Number.prototype, 'compareTo', {
    value: function (to: number) {
      if (+this < +to) {
        return -1;
      } else if (+this > +to) {
        return 1;
      } else {
        return 0;
      }
    },
    configurable: true,
    writable: true
  });
}

if (!Number.prototype.replaceCommaDecimalSeparator) {
  Object.defineProperty(Number.prototype, 'replaceCommaDecimalSeparator', {
    value: function () {
      return this.toString().replace(',', '.');
    },
    configurable: true,
    writable: true
  });
}

if (!String.prototype.compareTo) {
  Object.defineProperty(String.prototype, 'compareTo', {
    value: function (to: string) {
      if (this.toUpperCase() < to.toUpperCase()) {
        return -1;
      } else if (this.toUpperCase() > to.toUpperCase()) {
        return 1;
      } else {
        return 0;
      }
    },
    configurable: true,
    writable: true
  });
}

if (!String.prototype.toBooleanString) {
  Object.defineProperty(String.prototype, 'toBooleanString', {
    value: function () {
     switch (simplifyStringForSearch(this)) {
       case 'oui':
       case 'yes':
          return 'true';
       case 'non':
       case 'no':
         return 'false';
       default:
         return this;
     }
    },
    configurable: true,
    writable: true
  });
}

if (!Date.prototype.compareTo) {
  Object.defineProperty(Date.prototype, 'compareTo', {
    value: function (to: Date) {
      if (this < to) {
        return -1;
      } else if (this > to) {
        return 1;
      } else {
        return 0;
      }
    },
    configurable: true,
    writable: true
  });
}

if (!Array.prototype.singleOrNull) {
  Object.defineProperty(Array.prototype, 'singleOrNull', {
    value: function <T>(predicate: (value: T) => boolean): T | null {
      let single: T = null;
      let found = false;
      for (const element of this) {
        if (predicate(element)) {
          if (found) {
            return null;
          }
          single = element;
          found = true;
        }
      }
      if (!found) {
        return null;
      }
      return single;
    },
    configurable: true,
    writable: true
  });
}

if (!Array.prototype.lastOrNull) {
  Object.defineProperty(Array.prototype, 'lastOrNull', {
    value: function <T>(predicate: (value: T) => boolean): T | null {
      for (let index = this.length - 1; index >= 0; index--) {
        if (predicate(this[index])) {
          return this[index];
        }
      }
      return null;
    },
    configurable: true,
    writable: true
  });
}

if (!Array.prototype.firstOrNull) {
  Object.defineProperty(Array.prototype, 'firstOrNull', {
    value: function <T>(predicate: (value: T) => boolean): T | null {
      for (let index = 0; index <= this.length - 1; index++) {
        if (predicate(this[index])) {
          return this[index];
        }
      }
      return null;
    },
    configurable: true,
    writable: true
  });
}

if (!Array.prototype.distinctBy) {
  Object.defineProperty(Array.prototype, 'distinctBy', {
    value: function <T>(property: any): T[] {
      const seenItems = new Set();
      const result = [];
      for (let index = 0; index < this.length; index++) {
        const item = this[index];
        if (item.hasOwnProperty(property) && !seenItems.has(item[property])) {
          seenItems.add(item[property]);
          result.push(item);
        }
      }
      return result;
    },
    configurable: true,
    writable: true
  });
}

if (!Array.prototype.last) {
  Object.defineProperty(Array.prototype, 'last', {
    value: function <T>(): T | undefined {
      const len = this.length;
      return len == 0 ? undefined : this[len - 1];
    },
    configurable: true,
    writable: true
  });
}

if (!Array.prototype.first) {
  Object.defineProperty(Array.prototype, 'first', {
    value: function <T>(): T | undefined {
      const len = this.length;
      return len == 0 ? undefined : this[0];
    },
    configurable: true,
    writable: true
  });
}
