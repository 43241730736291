export enum ConditionOperator {
  EMPTY = 'EMPTY',
  EXISTS = 'EXISTS',
  CONTAINS = 'CONTAINS',
  NOT_CONTAINS = 'NOT_CONTAINS',
  ALWAYS = 'ALWAYS',
  NEVER = 'NEVER',
  EQUALS = 'EQUALS',
  NOT_EQUALS = 'NOT_EQUALS',
  GREATER_THAN = 'GREATER_THAN',
  LESS_THAN = 'LESS_THAN'
}
